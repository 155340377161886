import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/productsSingle.css";
import { FaRegHeart } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { addToCart } from "../redux/actions/cartActions";
import { useSelector } from "react-redux";
import UtilsHelmet from "../utils/helmet";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { removeTurkishChars } from "../utils/urlUtils";
import FavoriteButton from "../utils/FavoriteButton";
import config from "../config";
import Loading from "../wrappers/Loading";
import ReactHtmlParser from "react-html-parser";
import AddBasketButton from "../utils/AddBasketButton";
import useStorageListener from "../utils/useStorageListener";
import { setMetaDescription } from "../utils/setMetaDescription";

const { REACT_APP_BASE_URL } = process.env;
const noImage = "product_images/no_pic.webp";

const ProductsSingleComponent = ({ isLoggedIn, cartItems }) => {
  useStorageListener();

  const [selectedImg, setSelectedImg] = useState("");
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);
  const [counter, setCounter] = useState(1);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [selectedAttributeImages, setSelectedAttributeImages] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [product, setProduct] = useState("");
  const { page_url } = useParams();
  const [activeAttribute, setActiveAttribute] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const userDetails = useSelector((state) => state.account.userDetails);
  const token = useSelector((state) => state.token);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleClick = (image) => {
    setSelectedImg(image);
  };

  useEffect(() => {
    product &&
      product.comb_prices.map((item) => {
        item.comb_atts.map((item2) => {
          if (
            item2.attribute_value.value_name == activeAttribute ||
            item2.attribute_value.value_name_en == activeAttribute
          ) {
            setPrice(item.price);
          }
        });
      });
  }, [activeAttribute]);

  const handleAttributeClick = (attribute) => {
    const filteredImages = product.product_images.filter(
      (image) =>
        image.image_attribute === attribute ||
        image.image_attribute_en === attribute
    );
    const url = new URL(window.location.href);
    const formattedAttribute = attribute.replace(/ /g, "-");
    url.searchParams.set("attribute", formattedAttribute);
    window.history.replaceState({}, "", url);
    if (filteredImages.length > 0) {
      setSelectedImg(filteredImages[0].image_url);

      setSelectedAttributeImages(filteredImages);
    } else {
      setSelectedImg(noImage);

      setSelectedAttributeImages([
        { image_name: "none", image_url: noImage, image_path: attribute },
      ]);
    }
    setActiveAttribute(attribute);
    if (!selectedAttributes.includes(attribute)) {
      setSelectedAttributes([...selectedAttributes, attribute]);
    }

    setSelectedAttributes([attribute]);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const attributeParam = urlParams.get("attribute");

    axios
      .post(
        REACT_APP_BASE_URL + "web/search/product-url",
        { search: page_url },
        { headers: { xsfr: config.secretKey } }
      )
      .then((response) => {
        setProduct(response.data.response);

        if (response.data.response) {
          const initialAttribute = attributeParam
            ? attributeParam.replace(/-/g, " ")
            : language == "en"
            ? response.data.response.comb_prices[0].comb_atts[0].attribute_value
                .value_name_en
            : response.data.response.comb_prices[0].comb_atts[0].attribute_value
                .value_name;

          setActiveAttribute(initialAttribute);
          setSelectedAttributes([initialAttribute]);

          const filteredImages = response.data.response.product_images.filter(
            (image) =>
              image.image_attribute === initialAttribute ||
              image.image_attribute_en === initialAttribute
          );

          if (filteredImages.length > 0) {
            setSelectedImg(filteredImages[0].image_url);
          } else {
            setSelectedImg(noImage);
            setSelectedAttributeImages([
              {
                image_name: "none",
                image_url: noImage,
                image_path: initialAttribute,
              },
            ]);
          }
          setMetaDescription(
            `${response.data.response.product_name}, geniş ve çeşitli ürün yelpazemizle herkesin ihtiyacını karşılamaya çalışıyoruz.`
          );

          setSelectedAttributeImages(filteredImages);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [page_url]);

  useEffect(() => {
    if (selectedAttribute !== "") {
      const filteredImages = product.product_images.filter(
        (image) =>
          image.image_attribute === selectedAttribute ||
          image.image_attribute_en === selectedAttribute
      );

      setSelectedAttributeImages(filteredImages);
    }
  }, [selectedAttribute, language]);

  const dispatch = useDispatch();
  const addBasket = async (e, product) => {
    if (selectedAttributes.length === 0) {
      document.getElementById("attributeWarning").style.display = "block";
      return;
    } else {
      document.getElementById("attributeWarning").style.display = "none";
    }

    const combAtts = selectedAttributes.map((attribute) => {
      const selectedCombPrice = product.comb_prices.find((combPrice) => {
        return combPrice.comb_atts.some(
          (combAtt) =>
            combAtt.attribute_value.value_name === attribute ||
            combAtt.attribute_value.value_name_en === attribute
        );
      });

      if (!selectedCombPrice) {
        toast.error("Seçilen özellikler için kombinasyon bulunamadı.");
        return null;
      }

      const combAttId = selectedCombPrice.comb_atts.find(
        (combAtt) =>
          combAtt.attribute_value.value_name === attribute ||
          combAtt.attribute_value.value_name_en === attribute
      ).comb_att_id;
      return {
        comb_att_id: combAttId,
        quantity: counter.toString(),
        price: selectedCombPrice.price,
      };
    });

    if (!isLoggedIn) {
      const updatedItem = {
        product_name:
          language == "en" ? product.product_name_en : product.product_name,
        product_id: product.product_id,
        product_price: price,
        product_category_name:
          language == "en"
            ? product.categories[0].category_name_en
            : product.categories[0].category_name,
        product_image_url: selectedImg,
        comb_att_id: combAtts[0].comb_att_id,
        quantity: combAtts[0].quantity,
        count: counter,
        product_image_attributes: selectedAttributes.join(", "),
      };

      dispatch(addToCart(updatedItem));
      toast.success(data.urun_sepete_eklendi);
      return;
    }

    try {
      const findCartResponse = await axios.post(
        REACT_APP_BASE_URL + "cart/find",
        {
          user_id: userDetails.user_id,
        },
        {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        }
      );
      const existingCarts = findCartResponse.data.cart;
      if (existingCarts) {
        const existingCart = existingCarts.carts[0];
        const combinedCombAtts = [...existingCart.comb_atts, ...combAtts];
        const results = cartItems.filter((items) =>
          combAtts.some((item2) => items.comb_att_id == item2.comb_att_id)
        );

        var updateCartResponse = "";
        if (results.length > 0 && combAtts.length > 0) {
          updateCartResponse = await axios.post(
            REACT_APP_BASE_URL + "cart/update",
            {
              cart_id: existingCart.cart_id,
              comb_atts: [
                {
                  comb_att_id: combAtts[0].comb_att_id,
                  quantity: results[0].count + 1,
                },
              ],
            },
            {
              headers: {
                xsfr: config.secretKey,
                Authorization: `Bearer ${token.tokenAuth}`,
              },
            }
          );
        } else {
          updateCartResponse = await axios.post(
            REACT_APP_BASE_URL + "cart/update",
            {
              cart_id: existingCart.cart_id,
              comb_atts: combinedCombAtts,
            },
            {
              headers: {
                xsfr: config.secretKey,
                Authorization: `Bearer ${token.tokenAuth}`,
              },
            }
          );
        }

        if (
          updateCartResponse.data.message ===
          "Cart details updated successfully"
        ) {
          const priceResult = product.comb_prices.filter((item) =>
            item.comb_atts.some(
              (item2) =>
                item2.attribute_value.value_name ==
                selectedAttributes.join(", ")
            )
          );

          const updatedItem = {
            cart_id: existingCart.cart_id,
            comb_att_id: priceResult[0].comb_atts[0].comb_att_id,
            product_name:
              language == "en" ? product.product_name_en : product.product_name,
            product_id: product.product_id,
            product_price: priceResult[0].price,
            product_category_name:
              language == "en"
                ? product.categories[0].category_name_en
                : product.categories[0].category_name,
            product_image_url: selectedImg,
            count: counter,
            product_image_attributes: selectedAttributes.join(", "),
          };
          dispatch(addToCart(updatedItem));
          localStorage.setItem("taskCompleted", Date.now());

          toast.success(data.urun_sepete_guncellendi);
        } else {
          toast.error(data.sepete_guncellerken_hata);
        }
      } else {
        const addCartResponse = await axios.post(
          REACT_APP_BASE_URL + "cart/add",
          {
            user_id: userDetails.user_id,
            comb_atts: combAtts,
          },
          {
            headers: {
              xsfr: config.secretKey,
              Authorization: `Bearer ${token.tokenAuth}`,
            },
          }
        );
        if (addCartResponse.data.message === "Sepet başarıyla oluşturuldu.") {
          const updatedItem = {
            cart_id: addCartResponse.data.cart.cart_id,
            comb_att_id: addCartResponse.data.cartDetails[0].comb_att_id,
            product_name:
              language == "en" ? product.product_name_en : product.product_name,
            product_id: product.product_id,
            product_price: combAtts[0].price,
            product_category_name:
              language == "en"
                ? product.categories[0].category_name_en
                : product.categories[0].category_name,
            product_image_url: selectedImg,
            count: counter,
            product_image_attributes: selectedAttributes.join(", "),
          };
          dispatch(addToCart(updatedItem));
          toast.success(data.urun_sepete_eklendi);
        } else {
          toast.error(data.sepete_eklerken_hata);
        }
      }
    } catch (error) {
      toast.error(data.sepete_eklerken_hata);
    }
  };

  useEffect(() => {
    if (product && product.categories && product.categories.length > 0) {
      const selectedCategory = product.categories[0].category_path;
      axios
        .post(
          REACT_APP_BASE_URL + "web/read/category-find",
          {
            search: selectedCategory,
          },
          {
            headers: {
              xsfr: config.secretKey,
            },
          }
        )
        .then((response) => {
          const similarProducts = response.data.categories[0].products;
          setSimilarProducts(similarProducts);
        })
        .catch((error) => {});
    }
  }, [product]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {product && (
            <>
              <div className="products-single-main container-fluid main-space">
                <UtilsHelmet
                  title={product.product_name}
                  canonical={`https://www.perfumeatelier.com.tr/${language}/urun-detay/${encodeURIComponent(
                    removeTurkishChars(
                      language == "en"
                        ? product.page_url_en.toLowerCase().replace(/ /g, "-")
                        : product.page_url.toLowerCase().replace(/ /g, "-")
                    )
                  )}?attribute=${
                    language == "en"
                      ? product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                          / /g,
                          "-"
                        )
                      : product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                          / /g,
                          "-"
                        )
                  }`}
                />
                <div className="col-12 d-lg-flex justify-content-between align-items-start">
                  <div className="col-lg-5 col-12 d-flex gap-lg-4 gap-2 ">
                    <div className="col-2 slider-small-img d-flex flex-column gap-3">
                      {selectedAttributeImages.map((image, index) => {
                        return (
                          <img
                            crossOrigin="anonymous"
                            key={index}
                            alt={image.image_url}
                            className={
                              selectedImg ===
                              REACT_APP_BASE_URL + image.image_url
                                ? "card-type-one selected"
                                : "card-type-one"
                            }
                            src={REACT_APP_BASE_URL + image.image_url}
                            onClick={() => handleClick(image.image_url)}
                          />
                        );
                      })}
                    </div>
                    <div className="col-10">
                      {selectedImg && (
                        <img
                          crossOrigin="anonymous"
                          className="card-type-one w-100"
                          src={REACT_APP_BASE_URL + selectedImg}
                          alt={selectedImg}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                    <h1>
                      <b>
                        {language == "en"
                          ? product.product_name_en
                          : product.product_name}
                      </b>
                    </h1>
                    <h2 style={{ color: "#495057" }}>
                      {product.comb_prices.length > 0 &&
                        product.comb_prices.map((combatts, index) => {
                          const active =
                            combatts.comb_atts.length > 0 &&
                            combatts.comb_atts.filter(
                              (item) =>
                                item.attribute_value.value_name ==
                                activeAttribute
                            );

                          return (
                            <>
                              {active.length > 0
                                ? language == "en"
                                  ? active[0].attribute_value.value_name_en
                                  : active[0].attribute_value.value_name
                                : ""}
                            </>
                          );
                        })}
                    </h2>
                    {product.comb_prices.length > 0 ? (
                      <h3>{price}₺</h3>
                    ) : (
                      <p>Fiyat bulunamadı</p>
                    )}

                    <div className="col-lg-12 col-11 d-flex gap-3 mt-4 flex-wrap">
                      {product.comb_prices.map((combatts, index) => {
                        return (
                          <button
                            key={index}
                            className={`button-type-four col-4 ${
                              activeAttribute ===
                              combatts.comb_atts[0].attribute_value.value_name
                                ? "active"
                                : "" ||
                                  activeAttribute ===
                                    combatts.comb_atts[0].attribute_value
                                      .value_name_en
                                ? "active"
                                : ""
                            }`}
                            style={{
                              width: "max-content",
                              backgroundColor:
                                activeAttribute ===
                                combatts.comb_atts[0].attribute_value.value_name
                                  ? "#DEF5EA"
                                  : "" ||
                                    activeAttribute ===
                                      combatts.comb_atts[0].attribute_value
                                        .value_name_en
                                  ? "#DEF5EA"
                                  : "",
                              backgroundImage:
                                activeAttribute ===
                                combatts.comb_atts[0].attribute_value.value_name
                                  ? "none"
                                  : "" ||
                                    activeAttribute ===
                                      combatts.comb_atts[0].attribute_value
                                        .value_name_en
                                  ? "none"
                                  : "",
                            }}
                            onClick={() =>
                              handleAttributeClick(
                                language == "en"
                                  ? combatts.comb_atts[0].attribute_value
                                      .value_name_en
                                  : combatts.comb_atts[0].attribute_value
                                      .value_name
                              )
                            }
                          >
                            <div className="d-flex align-items-center">
                              {combatts.comb_atts.length > 0 &&
                                combatts.comb_atts[0].attribute_value &&
                                combatts.comb_atts[0].attribute_value
                                  .image_path && (
                                  <img
                                    className="icon-svg"
                                    crossOrigin="anonymous"
                                    src={
                                      REACT_APP_BASE_URL +
                                      combatts.comb_atts[0].attribute_value
                                        .image_path
                                    }
                                    alt={
                                      combatts.comb_atts[0].attribute_value
                                        .image_path
                                    }
                                  />
                                )}
                              {language == "en"
                                ? combatts.comb_atts.length > 0 &&
                                  combatts.comb_atts[0].attribute_value
                                    .value_name_en
                                : combatts.comb_atts.length > 0 &&
                                  combatts.comb_atts[0].attribute_value
                                    .value_name}
                            </div>
                          </button>
                        );
                      })}
                    </div>

                    <div
                      className="mt-3"
                      id="attributeWarning"
                      style={{
                        color: "red",
                        display: "none",
                        fontSize: "11px",
                      }}
                    >
                      Lütfen bir ürün boyutu seçiniz.
                    </div>

                    <div className="col-lg-8 col-11 d-flex gap-3 mt-4">
                      <button
                        onClick={(e) => addBasket(e, product)}
                        className="button-type-one col-4"
                        style={{ width: "max-content" }}
                      >
                        {data.sepete_ekle}
                      </button>
                      <button className="col-2 favicon">
                        <FavoriteButton product={product} />
                      </button>
                    </div>

                    <div className="col-12 products-inner-desc mt-4">
                      {product.product_description && (
                        <>
                          <p>Ürün Açıklaması:</p>
                          <p>{ReactHtmlParser(product.product_description)}</p>
                          <br />
                        </>
                      )}
                      {product.kokuprofili && (
                        <>
                          <p> {data.koku_profili}</p>
                          <p>{product.kokuprofili}</p>
                          <br />
                        </>
                      )}
                      <br />

                      <div className="col-12">
                        {product.tepeustnota && (
                          <>
                            <p> {data.tepe_ust_nota}</p>
                            <p> {product.tepeustnota} </p>
                            <br />
                          </>
                        )}
                      </div>
                      <div className="col-12">
                        {product.ortanota && (
                          <>
                            <p> {data.orta_nota}</p>
                            <p> {product.ortanota}</p>
                            <br />
                          </>
                        )}
                      </div>

                      <div className=" col-12">
                        {product.dipnota && (
                          <>
                            <p> {data.dip_nota}</p>
                            <p> {product.dipnota}</p>
                          </>
                        )}
                      </div>
                    </div>

                    {product.RelatedProducts.length > 0 && (
                      <div className="col-lg-11 col-12 mt-5 preferred">
                        <h2> {data.birlikte_tercih_edilenler}</h2>
                        <div className="preferred-inner d-flex gap-4">
                          {product.RelatedProducts.slice(0, 3).map(
                            (relatedProduct, index) => (
                              <div
                                key={index}
                                className="card-type-two d-lg-flex d-md-flex p-3 col-xl-9 col-lg-11 col-md-7 col-9"
                              >
                                <div className="col-lg-5 col-md-5 col-12 d-flex justify-content-center align-items-center">
                                  {relatedProduct.product_images[0] ? (
                                    <img
                                      crossOrigin="anonymous"
                                      src={`${REACT_APP_BASE_URL}${relatedProduct.product_images[0].image_url}`}
                                      alt={relatedProduct.product_name}
                                    />
                                  ) : (
                                    <img src={noImage} alt=""></img>
                                  )}
                                </div>
                                <div className="col-lg-7 col-md-7 col-12 d-flex flex-column justify-content-between">
                                  <div className="col-12 d-flex justify-content-start">
                                    <FaRegHeart className="heart-icon mb-2" />
                                  </div>
                                  <div>
                                    <h2 className="title-main">
                                      {relatedProduct.product_name && (
                                        <b>{relatedProduct.product_name}</b>
                                      )}
                                    </h2>
                                    <p>
                                      {
                                        relatedProduct.categories[0]
                                          .category_name
                                      }
                                    </p>
                                    <p className="p-0 m-0">
                                      {relatedProduct.comb_prices.length > 0
                                        ? `${relatedProduct.comb_prices[0].price}₺`
                                        : "Fiyat bulunamadı"}
                                    </p>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() => {
                                        const updatedItem = {
                                          product_name:
                                            language == "en"
                                              ? relatedProduct.product_name_en
                                              : relatedProduct.product_name,
                                          product_id: relatedProduct.product_id,
                                          product_price:
                                            relatedProduct.comb_prices[0].price,
                                          product_category_name:
                                            language == "en"
                                              ? relatedProduct.categories[0]
                                                  .category_name_en
                                              : relatedProduct.categories[0]
                                                  .category_name,
                                          product_image_url:
                                            relatedProduct.product_images[0]
                                              .image_url,
                                          comb_att_id:
                                            relatedProduct.comb_prices[0]
                                              .comb_atts[0].comb_att_id,
                                          quantity: "1",
                                          count: 1,
                                          product_image_attributes: "Standart",
                                        };
                                        dispatch(addToCart(updatedItem));
                                        toast.success(data.urun_sepete_eklendi);
                                      }}
                                      className="button-type-two w-100"
                                    >
                                      {data.sepete_ekle}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 mt-5">
                  <h2> {data.benzer_urunler}</h2>
                  <div className="d-flex preferred-inner gap-5">
                    {similarProducts.map((similarProduct, index) => (
                      <div
                        key={index}
                        className="card-type-one col-xl-3 col-lg-3 col-md-4 col-7 d-flex flex-column justify-content-center p-2"
                      >
                        <div className="col-12 d-flex justify-content-end">
                          <FavoriteButton product={similarProduct} />
                        </div>
                        <a
                          href={`/${language}/urun-detay/${removeTurkishChars(
                            similarProduct.page_url
                              .toLowerCase()
                              .replace(/ /g, "-")
                          )}?attribute=${
                            similarProduct.comb_prices[0]?.comb_atts.length >
                              0 &&
                            similarProduct.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                              / /g,
                              "-"
                            )
                          }`}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {similarProduct.product_images[0] ? (
                              <img
                                crossOrigin="anonymous"
                                src={`${REACT_APP_BASE_URL}${similarProduct.product_images[0].image_url}`}
                                alt={similarProduct.product_name}
                              />
                            ) : (
                              <img src={noImage} alt=""></img>
                            )}
                          </div>
                          <h2 className="title-main">
                            <b>
                              {language == "en"
                                ? similarProduct.product_name_en
                                : similarProduct.product_name}
                            </b>
                          </h2>
                          <p>
                            <span>
                              {language == "en"
                                ? similarProduct.comb_prices[0]?.comb_atts
                                    .length > 0 &&
                                  similarProduct.comb_prices[0]?.comb_atts[0]
                                    .attribute_value.value_name_en
                                : similarProduct.comb_prices[0]?.comb_atts
                                    .length > 0 &&
                                  similarProduct.comb_prices[0]?.comb_atts[0]
                                    .attribute_value.value_name}
                            </span>
                          </p>
                          {similarProduct.comb_prices.length > 0 ? (
                            <h6>
                              <b>{similarProduct.comb_prices[0].price}₺</b>
                            </h6>
                          ) : (
                            <p>Fiyat bulunamadı</p>
                          )}
                        </a>
                        <AddBasketButton product={similarProduct} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

ProductsSingleComponent.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    cartItems: state.cart.cartItems,
  };
};

export default connect(mapStateToProps)(ProductsSingleComponent);
