import React, { useState, useEffect } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiEditLine } from "react-icons/ri";
import { LuPlus } from "react-icons/lu";
import Modal from "react-modal";
import "../assets/css/userAccount.css";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  addAddress,
  updateAddress,
  deleteAddress,
} from "../redux/actions/accountActions";
import config from "../config";
import citiesData from "../data/city-district.json";

const { REACT_APP_BASE_URL } = process.env;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -40%)",
    borderRadius: "10px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    width: "40%",
    maxHeight: "80vh",
    overflowY: "auto",
    backgroundColor: "white",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

if (window.matchMedia("(max-width: 768px)").matches) {
  customStyles.content.width = "92%";
  customStyles.content.maxHeight = "66%";
} else if (
  window.matchMedia("(min-width: 769px) and (max-width: 1024px)").matches
) {
  customStyles.content.width = "75%";
}

const Address = ({
  setSelectedShippingAdrress,
  address_type,
  setSelectedInvoiceAdrress,
}) => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const userDetails = useSelector((state) => state.account.userDetails);
  const token = useSelector((state) => state.token);
  const { data } = useSelector((state) => state.language);

  const [newAddress, setNewAddress] = useState({
    address_title: "",
    address_area1: "",
    address_area2: "",
    city: "",
    district: "",
    postcode: "",
    phone_no: "",
  });

  const [addressList, setAddressList] = useState(
    Array.isArray(userDetails.user_addresses) ? userDetails.user_addresses : []
  );

  useEffect(() => {
    setAddressList(
      Array.isArray(userDetails.user_addresses)
        ? userDetails.user_addresses
        : []
    );
  }, [userDetails]);

  const handleAddressClick = (e, address, index) => {
    e.preventDefault();
    if (address_type == "invoice") {
      setSelectedInvoiceAdrress(address);
    } else if (address_type == "shipping") {
      setSelectedShippingAdrress(address);
    }

    setSelectedCardIndex(index);
  };

  useEffect(() => {
    setCities(citiesData);
  }, []);

  const handleCityChange = (event) => {
    const selectedCityName = event.target.value;
    setSelectedCity(selectedCityName);
    setNewAddress({ ...newAddress, city: selectedCityName });

    const selectedCityData = cities.find(
      (city) => city.name === selectedCityName
    );

    if (selectedCityData) {
      setDistricts(selectedCityData.districts.map((district) => district.name));
    } else {
      setDistricts([]);
    }
    setSelectedDistrict("");
  };
  const dispatch = useDispatch();

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
    setNewAddress({ ...newAddress, district: event.target.value });
  };

  const handleEditClick = (address) => {
    setSelectedAddress(address);
    setSelectedCity(address.city);
    setSelectedDistrict(address.district);
    setNewAddress({ ...address });
    setEditModalIsOpen(true);
  };

  const handleAddClick = () => {
    setAddModalIsOpen(true);
  };
  useEffect(() => {}, [selectedAddress]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleAddAddress = () => {
    if (!newAddress.address_title.trim()) {
      toast.error(data.adres_basligi_bos_birakilamaz);
      return;
    }
    if (newAddress.address_title.length > 25) {
      toast.error(data.adres_basligi_cok_uzun);
      return;
    }

    if (!newAddress.address_area1.trim()) {
      toast.error(data.adres_alani_bir_bos_birakilamaz);
      return;
    }

    const postcodePattern = /^[0-9]{5}$/;
    if (!postcodePattern.test(newAddress.postcode)) {
      toast.error(data.gecersiz_posta_kodu);
      return;
    }

    const phonePattern = /^[0-9]{11}$/;
    if (!phonePattern.test(newAddress.phone_no)) {
      toast.error(data.gecersiz_telefon);
      return;
    }

    const userId = userDetails.user_id;
    const addressData = {
      user_id: userId,
      ...newAddress,
    };

    fetch(REACT_APP_BASE_URL + "users/add/address", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        xsfr: config.secretKey,
        Authorization: `Bearer ${token.tokenAuth}`,
      },
      body: JSON.stringify(addressData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.message === "Adres eklendi." &&
          data.response &&
          data.response.address_id
        ) {
          toast.success(data.adres_eklendi);

          const addedAddress = {
            ...newAddress,
            address_id: data.response.address_id,
          };
          setAddressList([...addressList, addedAddress]);
          dispatch(addAddress(addedAddress));

          setAddModalIsOpen(false);
        } else {
          toast.error(data.adres_eklenmedi);
          console.error("Error adding address:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding address:", error);
      });
  };

  const handleDeleteAddress = (addressId) => {
    fetch(REACT_APP_BASE_URL + "users/delete/address", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        xsfr: config.secretKey,
        Authorization: `Bearer ${token.tokenAuth}`,
      },
      body: JSON.stringify({ address_id: addressId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Adres silindi.") {
          toast.success(data.adres_silindi);
          const updatedAddressList = addressList.filter(
            (address) => address.address_id !== addressId
          );
          setAddressList(updatedAddressList);
          dispatch(deleteAddress(addressId));
        } else {
          toast.error(data.adres_silinemedi);
          console.error("Error deleting address:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error deleting address:", error);
      });
  };

  const handleEditAddress = () => {
    if (!selectedAddress) {
      console.error("No address selected for editing.");
      return;
    }

    // Boş alanları kontrol et
    if (!newAddress.address_title.trim()) {
      toast.error(data.adres_basligi_bos_birakilamaz);
      return;
    }
    if (!newAddress.address_area1.trim()) {
      toast.error(data.adres_alani_bir_bos_birakilamaz);
      return;
    }

    if (!newAddress.city) {
      toast.error(data.sehir_bos_birakilamaz);
      return;
    }
    if (!newAddress.district) {
      toast.error(data.ilce_bos_birakilamaz);
      return;
    }
    const postcodePattern = /^[0-9]{5}$/;
    if (!postcodePattern.test(newAddress.postcode)) {
      toast.error(data.gecersiz_posta_kodu);
      return;
    }
    const phonePattern = /^[0-9]{11}$/;
    if (!phonePattern.test(newAddress.phone_no)) {
      toast.error(data.gecersiz_telefon);
      return;
    }
    const addressData = {
      user_id: userDetails.user_id,
      ...newAddress,
      address_id: selectedAddress.address_id,
    };

    fetch(REACT_APP_BASE_URL + "users/update/address", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        xsfr: config.secretKey,
        Authorization: `Bearer ${token.tokenAuth}`,
      },
      body: JSON.stringify(addressData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Adres güncellendi.") {
          toast.success(data.adres_guncellendi);
          const updatedAddressList = addressList.map((address) =>
            address.address_id === selectedAddress.address_id
              ? addressData
              : address
          );
          setAddressList(updatedAddressList);
          dispatch(updateAddress(addressData));

          setEditModalIsOpen(false);
        } else {
          toast.error(data.adres_guncellenemedi);
          console.error("Error updating address:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating address:", error);
      });
  };

  return (
    <div className="address-main">
      <h2 className="user-account-inner-title">
        <b>{data.adresler} </b>
      </h2>
      <div className="d-lg-flex flex-wrap gap-2">
        {addressList.map((address, index) => (
          <div
            key={index}
            className={`col-12 card-type-two p-2 mb-lg-0 mb-3 ${
              selectedCardIndex === index ? "selected" : ""
            }`}
            onClick={(e) => handleAddressClick(e, address, index)}
          >
            <div className="d-flex justify-content-between">
              <h5>
                <b>{address.address_title}</b>
              </h5>
              <div className="d-flex gap-1">
                <RiEditLine onClick={() => handleEditClick(address)} />
                <FaRegTrashAlt
                  onClick={() => handleDeleteAddress(address.address_id)}
                />
              </div>
            </div>
            <p className="p-0 m-0">{address.postcode}</p>
            <p className="p-0 m-0">
              {address.address_area1} &nbsp;
              {address.address_area2}
              <br /> {address.city} / {address.district}
            </p>
          </div>
        ))}
        <div
          className="col-12 card-type-two p-2 d-flex justify-content-center align-items-center address-add-icon flex-column cursor-pointer"
          onClick={handleAddClick}
        >
          <p>{data.yeni_adres_ekle} </p>
          <LuPlus />
        </div>
      </div>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        style={customStyles}
        contentLabel="Edit Address Modal"
      >
        <h4>{data.adres_duzenle} </h4>
        {selectedAddress && (
          <div className="d-grid adres-edit">
            <label className="mt-2">{data.adres_basligi} </label>
            <input
              className="mb-2"
              type="text"
              name="address_title"
              required
              value={newAddress.address_title}
              onChange={handleInputChange}
            />
            <label className="mt-2">{data.adres_alani_1} </label>
            <input
              className="mb-2"
              type="text"
              name="address_area1"
              required
              value={newAddress.address_area1}
              onChange={handleInputChange}
            />
            <label className="mt-2">{data.adres_alani_2} </label>
            <input
              className="mb-2"
              type="text"
              name="address_area2"
              required
              value={newAddress.address_area2}
              onChange={handleInputChange}
            />
            <div className="d-lg-flex d-md-flex justify-content-between">
              <div className="d-grid col-lg-5 col-12 col-md-5">
                <label className="mt-lg-2 mt-0">{data.sehir} </label>
                <div className="input-with-icon">
                  <select
                    className="w-100"
                    value={selectedCity}
                    onChange={handleCityChange}
                    name="city"
                  >
                    <option value="">{data.sehir_secin} </option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="d-grid col-lg-5 col-12 col-md-5">
                <label className="mt-2">{data.ilce} </label>
                <div className="input-with-icon">
                  <select
                    className="w-100"
                    value={selectedDistrict}
                    onChange={handleDistrictChange}
                    name="district"
                  >
                    <option value="">{data.ilce_secin} </option>
                    {districts.map((district) => (
                      <option key={district} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="d-lg-flex d-md-flex justify-content-between">
              <div className="d-grid col-lg-5 col-12 col-md-5">
                <label className="mt-2">{data.telefon} </label>
                <input
                  type="tel"
                  name="phone_no"
                  placeholder={data.telefon}
                  value={newAddress.phone_no}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-grid col-lg-5 col-12 col-md-5">
                <label className="mt-2">{data.posta_kodu} </label>
                <input
                  type="text"
                  name="postcode"
                  value={newAddress.postcode}
                  maxLength={5}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        )}
        <button className="button-type-one mt-3" onClick={handleEditAddress}>
          {data.adres_duzenle}
        </button>
      </Modal>

      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={() => setAddModalIsOpen(false)}
        style={customStyles}
        contentLabel="Add Address Modal"
      >
        <h4>{data.yeni_adres_ekle} </h4>
        <div className="d-grid adres-edit">
          <label className="mt-2">{data.adres_basligi} </label>
          <input
            className="mb-2"
            type="text"
            name="address_title"
            placeholder={data.adres_basligi}
            onChange={handleInputChange}
            minLength={2}
            maxLength={15}
            required
          />
          <label className="mt-2">{data.adres_alani_1} </label>

          <input
            className="mb-2"
            type="text"
            name="address_area1"
            placeholder={data.adres_alani_1}
            onChange={handleInputChange}
            minLength={1}
            maxLength={30}
            required
          />
          <label className="mt-2">{data.adres_alani_2} </label>

          <input
            className="mb-2"
            type="text"
            name="address_area2"
            placeholder={data.adres_alani_2}
            onChange={handleInputChange}
            required
          />
          <div className="d-lg-flex d-md-flex justify-content-between">
            <div className="d-grid col-lg-5 col-12 col-md-5">
              <label className="mt-2">{data.sehir} </label>
              <div className="input-with-icon">
                <select
                  className="w-100"
                  value={selectedCity}
                  onChange={handleCityChange}
                  name="city"
                >
                  <option value="">{data.sehir_secin} </option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-grid col-lg-5 col-12 col-md-5">
              <label className="mt-2">{data.ilce} </label>
              <div className="input-with-icon">
                <select
                  className="w-100"
                  onChange={handleDistrictChange}
                  name="district"
                >
                  <option value="">{data.ilce_secin} </option>
                  {districts.map((district) => (
                    <option key={district}>{district}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="d-lg-flex d-md-flex justify-content-between">
            <div className="d-grid col-lg-5 col-12 col-md-5">
              <label className="mt-2">{data.telefon} </label>
              <input
                type="tel"
                name="phone_no"
                placeholder={data.telefon}
                onChange={handleInputChange}
                maxLength={11}
                required
              />
            </div>
            <div className="d-grid col-lg-5 col-12 col-md-5">
              <label className="mt-2">{data.posta_kodu} </label>
              <input
                type="text"
                name="postcode"
                placeholder={data.posta_kodu}
                maxLength={5}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </div>
        <button className="button-type-one mt-3" onClick={handleAddAddress}>
          {data.kaydet}
        </button>
      </Modal>
    </div>
  );
};

export default Address;
